import {defineStore} from 'pinia'
import {$$, $computed, $ref} from '@vue-macros/reactivity-transform/macros'
import {
  IResourceItemResponseEntity,
} from '@toolify/server/src/adapters/EntityApiResponseAdapter/types/responseEntities/IResourceItemResponseEntity'
import {
  IResourceGroupResponseEntity,
} from '@toolify/server/src/adapters/EntityApiResponseAdapter/types/responseEntities/IResourceGroupResponseEntity'
import {useWorkspaceStore} from '@toolify/client/src/stores/WorkspaceStore/useWorkspaceStore'
import {watch} from 'vue'
import {useResourceItemApiStore} from '@toolify/client/src/stores/api/ResourceItemApiStore/useResourceItemApiStore'
import {useResourceGroupApiStore} from '@toolify/client/src/stores/api/ResourceGroupApiStore/useResourceGroupApiStore'
import {useActivityItemSocketListener} from '@toolify/client/src/composables/useActivityItemSocketListener'
import {ActivityItemType} from '@toolify/server/src/models/mongoose/ActivityItemModel/enum/ActivityItemType'
import {useSocketListener} from '@toolify/client/src/composables/useSocketListener'
import {
  IResourceLastActivityChangeEmitPayload,
} from '@toolify/server/src/services/SocketService/strategies/GlobalSocketEventTypeStrategy/IResourceLastActivityChangeEmitPayload'
import {GlobalSocketEventType} from '@toolify/server/src/services/SocketService/enum/GlobalSocketEventType'
import {
  IResourceReadAtChangeEmitPayload,
} from '@toolify/server/src/services/SocketService/strategies/GlobalSocketEventTypeStrategy/IResourceReadAtChangeEmitPayload'
import {
  INotificationCreatedEmitPayload,
} from '@toolify/server/src/services/SocketService/strategies/GlobalSocketEventTypeStrategy/INotificationCreatedEmitPayload'
import {mapResourceItemResponseEntity} from '@toolify/client/src/modules/api/singletons/mapResourceItemResponseEntity'
import {ResourceType} from '@toolify/server/src/models/mongoose/PermissionModel/enum/ResourceType'
import {useResourceSpaceApiStore} from '../api/ResourceSpaceApiStore/useResourceGroupApiStore'
import {IResourceSpaceResponseEntity} from '@toolify/server/src/adapters/EntityApiResponseAdapter/types/responseEntities/IResourceSpaceResponseEntity'

export const useResourceItemStore = defineStore('resourceItem', () => {
  const workspaceStore = useWorkspaceStore()
  const resourceItemApiStore = useResourceItemApiStore()
  const resourceGroupApiStore = useResourceGroupApiStore()
  const resourceSpaceApiStore = useResourceSpaceApiStore()
  
  let resourceItemsByWorkspaceIdMap = $ref<Map<string, IResourceItemResponseEntity[]>>(new Map())
  let resourceGroupsByWorkspaceIdMap = $ref<Map<string, IResourceGroupResponseEntity[]>>(new Map())
  let resourceSpacesByWorkspaceIdMap = $ref<Map<string, IResourceSpaceResponseEntity[]>>(new Map())

  let hasInitialized = $ref(false)
  function initialize() {
    hasInitialized = true
  }

  function dispose() {
    hasInitialized = false
  }

  const resourceItems = $computed(() => {
    const workspaceId = workspaceStore.currentWorkspace.id
    return resourceItemsByWorkspaceIdMap.get(workspaceId)
  })
  const sortedResourceItems = $computed(() => {
    return resourceItems?.sort((a, b) => {
      return a.position - b.position
    })
  })
  const resourceGroups = $computed(() => {
    const workspaceId = workspaceStore.currentWorkspace.id
    return resourceGroupsByWorkspaceIdMap.get(workspaceId)
  })
  const resourceSpaces = $computed(() => {
    const workspaceId = workspaceStore.currentWorkspace.id
    return resourceSpacesByWorkspaceIdMap?.get(workspaceId)?.sort() || []
  })
  const hasAnyResource = $computed(() => {
    return resourceItems?.length > 0
  })
  const hasLoaded = $computed(() => {
    const workspaceId = workspaceStore.currentWorkspace.id
    return resourceItemsByWorkspaceIdMap.has(workspaceId) && resourceGroupsByWorkspaceIdMap.has(workspaceId)
  })

  async function fetchResourceGroups(workspaceId: string) {
    const getResourceGroupsRes = await resourceGroupApiStore.getResourceGroups(workspaceId)
    setResourceGroupsByWorkspaceId(workspaceId, getResourceGroupsRes.resourceGroups)
  }
  async function fetchResourceItems(workspaceId: string) {
    const getResourceItemsRes = await resourceItemApiStore.getResourceItems(workspaceId)
    setResourceItemsByWorkspaceId(workspaceId, getResourceItemsRes.resourceItems)
  }
  async function fetchResourceSpaces(workspaceId: string) {
    const getResourceSpacesRes = await resourceSpaceApiStore.getResourceSpaces(workspaceId)
    setResourceSpacesByWorkspaceId(workspaceId, getResourceSpacesRes.resourceSpaces)
  }
  function setResourceItemsByWorkspaceId(workspaceId: string, resourceItems: IResourceItemResponseEntity[]) {
    const newResourceItemsByWorkspaceIdMap = new Map(resourceItemsByWorkspaceIdMap)
    newResourceItemsByWorkspaceIdMap.set(workspaceStore.currentWorkspace.id, resourceItems)
    resourceItemsByWorkspaceIdMap = newResourceItemsByWorkspaceIdMap
  }
  function setResourceGroupsByWorkspaceId(workspaceId: string, resourceGroups: IResourceGroupResponseEntity[]) {
    const newResourceGroupsByWorkspaceIdMap = new Map(resourceGroupsByWorkspaceIdMap)
    newResourceGroupsByWorkspaceIdMap.set(workspaceStore.currentWorkspace.id, resourceGroups)
    resourceGroupsByWorkspaceIdMap = newResourceGroupsByWorkspaceIdMap
  }
  function setResourceSpacesByWorkspaceId(workspaceId: string, resourceSpaces: IResourceSpaceResponseEntity[]) {
    const newResourceSpacesByWorkspaceIdMap = new Map(resourceSpacesByWorkspaceIdMap)
    newResourceSpacesByWorkspaceIdMap.set(workspaceStore.currentWorkspace.id, resourceSpaces)
    resourceSpacesByWorkspaceIdMap = newResourceSpacesByWorkspaceIdMap
  }
  function setResourceGroups(resourceGroups: IResourceGroupResponseEntity[]) {
    const workspaceId = workspaceStore.currentWorkspace.id
    if(!workspaceId) {
      return
    }
    setResourceGroupsByWorkspaceId(workspaceId, resourceGroups)
  }
  function setResourceItems(resourceItems: IResourceItemResponseEntity[]) {
    const workspaceId = workspaceStore.currentWorkspace.id
    if(!workspaceId) {
      return
    }
    setResourceItemsByWorkspaceId(workspaceId, resourceItems)
  }
  function setResourceSpaces(resourceSpaces: IResourceSpaceResponseEntity[]) {
    const workspaceId = workspaceStore.currentWorkspace.id
    if(!workspaceId) {
      return
    }
    setResourceSpacesByWorkspaceId(workspaceId, resourceSpaces)
  }
  async function markResourceAsRead(resourceType: ResourceType, resourceId: string) {
    await resourceItemApiStore.markResourceAsRead(resourceType, resourceId)
    // find this resource item in every workspace and update it
    const newResourceItemsByWorkspaceIdMap = new Map(resourceItemsByWorkspaceIdMap)
    for (const [workspaceId, resourceItems] of newResourceItemsByWorkspaceIdMap.entries()) {
      newResourceItemsByWorkspaceIdMap.set(workspaceId, resourceItems.map(resourceItem => {
        if(resourceItem.resourceEntity.resourceId === resourceId && resourceItem.resourceEntity.resourceType === resourceType) {
          return {
            ...resourceItem,
            readAt: new Date(),
          }
        }
        return resourceItem
      }))
    }
    resourceItemsByWorkspaceIdMap = newResourceItemsByWorkspaceIdMap
  }
  function subtractUnseenNotificationCountByResource(resourceType: ResourceType, resourceId: string) {
    const newResourceItemsByWorkspaceIdMap = new Map(resourceItemsByWorkspaceIdMap)
    for (const [workspaceId, resourceItems] of newResourceItemsByWorkspaceIdMap.entries()) {
      newResourceItemsByWorkspaceIdMap.set(workspaceId, resourceItems.map(resourceItem => {
        if(!resourceId && !resourceType) {
          return {
            ...resourceItem,
            unseenNotificationCount: 0,
          }
        }
        if(resourceItem.resourceEntity.resourceId === resourceId && resourceItem.resourceEntity.resourceType === resourceType) {
          return {
            ...resourceItem,
            unseenNotificationCount: 0,
          }
        }
        return resourceItem
      }))
    }
    resourceItemsByWorkspaceIdMap = newResourceItemsByWorkspaceIdMap
  }

  watch(() => {
    return workspaceStore.currentWorkspace?.id
  }, (workspaceId, oldValue) => {
    if(workspaceId === oldValue) {
      return
    }
    if(!workspaceId) {
      return
    }
    if(!resourceItemsByWorkspaceIdMap.has(workspaceId) && !resourceGroupsByWorkspaceIdMap.has(workspaceId) && !resourceSpacesByWorkspaceIdMap.has(workspaceId)) {
      fetchResourceItems(workspaceId)
      fetchResourceGroups(workspaceId)
      fetchResourceSpaces(workspaceId)
      return
    }
    if (!resourceItemsByWorkspaceIdMap.has(workspaceId)) {
      return fetchResourceItems(workspaceId)
    }
    if (!resourceGroupsByWorkspaceIdMap.has(workspaceId)) {
      return fetchResourceGroups(workspaceId)
    }
    if (!resourceSpacesByWorkspaceIdMap.has(workspaceId)) {
      return fetchResourceSpaces(workspaceId)
    }
  })

  useActivityItemSocketListener(ActivityItemType.ResourceItemCreated, ({payload, targetResource}) => {
    const workspaceId = payload.resourceItem.workspaceId
    const newResourceItemsByWorkspaceIdMap = new Map(resourceItemsByWorkspaceIdMap)
    if(!newResourceItemsByWorkspaceIdMap.has(workspaceId)) {
      return
    }
    const newResourceItems = newResourceItemsByWorkspaceIdMap.get(workspaceId)
    newResourceItems.push(mapResourceItemResponseEntity(payload.resourceItem))
    newResourceItemsByWorkspaceIdMap.set(workspaceId, newResourceItems)
    resourceItemsByWorkspaceIdMap = newResourceItemsByWorkspaceIdMap
  }, null, {
    lifecycleFn() {
      return hasInitialized
    },
  })

  useActivityItemSocketListener(ActivityItemType.ResourceItemRenamed, ({payload}) => {
    const newResourceItemsByWorkspaceIdMap = new Map(resourceItemsByWorkspaceIdMap)
    for (const [workspaceId, resourceItems] of newResourceItemsByWorkspaceIdMap.entries()) {
      newResourceItemsByWorkspaceIdMap.set(workspaceId, resourceItems.map(resourceItem => {
        if(resourceItem.id === payload.resourceItemId) {
          return {
            ...resourceItem,
            name: payload.name,
          }
        }
        return resourceItem
      }))
    }
    resourceItemsByWorkspaceIdMap = newResourceItemsByWorkspaceIdMap
  }, null, {
    lifecycleFn() {
      return hasInitialized
    },
  })

  useActivityItemSocketListener(ActivityItemType.ResourceItemDeleted, ({payload}) => {
    const newResourceItemsByWorkspaceIdMap = new Map(resourceItemsByWorkspaceIdMap)
    for (const [workspaceId, resourceItems] of newResourceItemsByWorkspaceIdMap.entries()) {
      newResourceItemsByWorkspaceIdMap.set(workspaceId, resourceItems.filter(resourceItem => {
        return resourceItem.id !== payload.resourceItemId
      }))
    }
    resourceItemsByWorkspaceIdMap = newResourceItemsByWorkspaceIdMap
  }, null, {
    lifecycleFn() {
      return hasInitialized
    },
  })

  useActivityItemSocketListener(ActivityItemType.ResourceItemPositionUpdated, ({payload}) => {
    const newResourceItemsByWorkspaceIdMap = new Map(resourceItemsByWorkspaceIdMap)
    for (const [workspaceId, resourceItems] of newResourceItemsByWorkspaceIdMap.entries()) {
      newResourceItemsByWorkspaceIdMap.set(workspaceId, resourceItems.map(resourceItem => {
        if(resourceItem.id === payload.resourceItemId) {
          return {
            ...resourceItem,
            position: payload.position,
            resourceGroupId: payload.resourceGroupId,
          }
        }
        return resourceItem
      }))
    }
    resourceItemsByWorkspaceIdMap = newResourceItemsByWorkspaceIdMap
  }, null, {
    lifecycleFn() {
      return hasInitialized
    },
  })

  useSocketListener<IResourceLastActivityChangeEmitPayload>(GlobalSocketEventType.ResourceLastActivityChange, payload => {
    const newResourceItemsByWorkspaceIdMap = new Map(resourceItemsByWorkspaceIdMap)
    for (const [workspaceId, resourceItems] of newResourceItemsByWorkspaceIdMap.entries()) {
      newResourceItemsByWorkspaceIdMap.set(workspaceId, resourceItems.map(resourceItem => {
        if(resourceItem.resourceEntity.resourceId === payload.resourceId && resourceItem.resourceEntity.resourceType === payload.resourceType) {
          return {
            ...resourceItem,
            lastActivityAt: new Date(payload.lastActivityAt),
          }
        }
        return resourceItem
      }))
    }
    resourceItemsByWorkspaceIdMap = newResourceItemsByWorkspaceIdMap
  }, {
    lifecycleFn() {
      return hasInitialized
    },
  })

  useSocketListener<IResourceReadAtChangeEmitPayload>(GlobalSocketEventType.ResourceReadAtChange, payload => {
    const newResourceItemsByWorkspaceIdMap = new Map(resourceItemsByWorkspaceIdMap)
    for (const [workspaceId, resourceItems] of newResourceItemsByWorkspaceIdMap.entries()) {
      newResourceItemsByWorkspaceIdMap.set(workspaceId, resourceItems.map(resourceItem => {
        if(resourceItem.id !== payload.resourceId) {
          return resourceItem
        }
        return {
          ...resourceItem,
          readAt: new Date(payload.readAt),
        }
      }))
    }
    resourceItemsByWorkspaceIdMap = newResourceItemsByWorkspaceIdMap
  }, {
    lifecycleFn() {
      return hasInitialized
    },
  })

  useSocketListener<INotificationCreatedEmitPayload>(GlobalSocketEventType.NotificationCreated, (payload) => {
    const notification = payload.notification
    if(notification.isTransient) {
      return
    }
    const newResourceItemsByWorkspaceIdMap = new Map(resourceItemsByWorkspaceIdMap)
    for (const [workspaceId, resourceItems] of newResourceItemsByWorkspaceIdMap.entries()) {
      newResourceItemsByWorkspaceIdMap.set(workspaceId, resourceItems.map(resourceItem => {
        const isNotificationAboutResourceItem = notification.resourcePath.some(resourcePathItem => {
          return resourcePathItem.resourceId === resourceItem.resourceEntity.resourceId &&
            resourcePathItem.resourceType === resourceItem.resourceEntity.resourceType
        })
        if(!isNotificationAboutResourceItem) {
          return resourceItem
        }
        return {
          ...resourceItem,
          unseenNotificationCount: resourceItem.unseenNotificationCount + 1,
        }
      }))
    }
    resourceItemsByWorkspaceIdMap = newResourceItemsByWorkspaceIdMap
  }, {
    lifecycleFn() {
      return hasInitialized
    },
  })

  return $$({
    resourceItems,
    sortedResourceItems,
    resourceGroups,
    resourceSpaces,
    hasLoaded,
    setResourceGroups,
    setResourceItems,
    setResourceSpaces,
    hasAnyResource,
    initialize,
    dispose,
    fetchResourceGroups,
    fetchResourceItems,
    fetchResourceSpaces,
    markResourceAsRead,
    subtractUnseenNotificationCountByResource,
  })
})
