<script setup lang="ts">
const ribbonText = ENV.EnvironmentName.toLowerCase().charAt(0).toUpperCase() + ENV.EnvironmentName.toLowerCase().slice(1)
</script>

<template>
  <div class="env-ribbon">{{ribbonText}}</div>
</template>

<style scoped lang="scss">
  @use '../../../styles/variables' as variables;

  .env-ribbon {
    position: fixed;
    z-index: 9999999;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background: map-get(variables.$colors, container-highest);
    color: map-get(variables.$colors, on-primary);
    padding: 2px 8px;
    font-weight: 700;
    font-size: 14px;
    border-radius: variables.$borderRadius;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
</style>
