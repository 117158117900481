<script setup lang="ts">
import LogoComponent from '@toolify/client/src/modules/branding/LogoComponent.vue'
import {useNativeAppStore} from '@toolify/client/src/stores/AppTitlebarStore/useNativeAppStore'
import {useAppUpdateStore} from '@toolify/client/src/stores/AppUpdateStore/useAppUpdateStore'
import {IconNameType} from '@toolify/server/src/enum/IconNameType'
import {StyleVariantType} from '@toolify/client/src/modules/ui/enum/StyleVariantType'
import {ColorVariantType} from '@toolify/client/src/modules/ui/enum/ColorVariantType'
import ButtonComponent from '@toolify/client/src/modules/ui/components/ButtonComponent.vue'

// interface Emits {
//   (e: 'event1', payload: string): void
// }
//
// interface Props {
//
// }
//
// const props = withDefaults(defineProps<Props>(), {})
//
// const emit = defineEmits<Emits>()

const nativeAppStore = useNativeAppStore()
const appUpdateStore = useAppUpdateStore()
</script>

<template>
  <div class="native-window-header">
    <div class="logos">
      <!--      <div class="logo-icon-wrapper">-->
      <!--        <LogoComponent isShort isWhite/>-->
      <!--      </div>-->
      <LogoComponent isWhite/>
    </div>
    <div class="buttons">
      <ButtonComponent
        :iconNameType="IconNameType.ArrowDownToLine"
        :style="StyleVariantType.ClearFilled"
        :color="ColorVariantType.Neutral"
        v-if="appUpdateStore.isUpdateAvailable"
        @click="appUpdateStore.downloadAppUpdate()"
      />
      <ButtonComponent
        :iconNameType="IconNameType.Refresh"
        :style="StyleVariantType.ClearFilled"
        :color="ColorVariantType.Neutral"
        v-if="appUpdateStore.isUpdateDownloaded"
        @click="appUpdateStore.restartAppAndUpdate()"
      />
      <ButtonComponent
        :iconNameType="IconNameType.SquareTerminal"
        :style="StyleVariantType.ClearFilled"
        :color="ColorVariantType.Neutral"
        @click="nativeAppStore.openDevTools()"
      />
      <ButtonComponent
        :iconNameType="IconNameType.Minus"
        :style="StyleVariantType.ClearFilled"
        :color="ColorVariantType.Neutral"
        @click="nativeAppStore.minimizeApp()"
      />
      <ButtonComponent
        :iconNameType="nativeAppStore.isWindowMaximized ? IconNameType.Compress : IconNameType.Expand"
        :style="StyleVariantType.ClearFilled"
        :color="ColorVariantType.Neutral"
        @click="nativeAppStore.toggleAppMaximize()"
      />
      <ButtonComponent
        :iconNameType="IconNameType.Xmark"
        :style="StyleVariantType.ClearFilled"
        :color="ColorVariantType.Error"
        @click="nativeAppStore.exitApp()"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '../../../styles/variables' as variables;

.native-window-header {
  height: 32px;
  background: map-get(variables.$colors, surface);
  z-index: 999999;
  -webkit-app-region: drag;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logos {
  margin-left: 10px;
  display: flex;
  gap: 10px;
}
.logo {
  font-size: 16px;
  opacity: 0.75;
}

.logo-icon-wrapper {
  background: map-get(variables.$colors, primary);
  width: 17px;
  height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.buttons {
  display: flex;
  height: 100%;
  -webkit-app-region: no-drag;
}
</style>
