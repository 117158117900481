<script setup lang="ts">
import {useAppUpdateStore} from '@toolify/client/src/stores/AppUpdateStore/useAppUpdateStore'
import ButtonComponent from '@toolify/client/src/modules/ui/components/ButtonComponent.vue'
import {IconNameType} from '@toolify/server/src/enum/IconNameType'
import {StyleVariantType} from '@toolify/client/src/modules/ui/enum/StyleVariantType'
import {ColorVariantType} from '@toolify/client/src/modules/ui/enum/ColorVariantType'

const appUpdateStore = useAppUpdateStore()
</script>

<template>
  <div class="native-app-update-bar">
    <div class="left">
      <i class="icon far fa-arrow-down"/>
      <div class="title" v-if="appUpdateStore.isUpdateDownloaded">New version has been downloaded. Restart app to see changes.</div>
      <div class="title" v-else-if="appUpdateStore.isUpdateInProgress">Downloading updates... ({{(appUpdateStore.updateProgress * 100).toFixed(1)}}%)</div>
      <div class="title" v-else-if="appUpdateStore.isUpdateAvailable">Update is available.</div>
    </div>
    <div class="right">
      <ButtonComponent
        :iconNameType="IconNameType.Refresh"
        :style="StyleVariantType.ClearFilled"
        :color="ColorVariantType.Neutral"
        v-if="appUpdateStore.isUpdateDownloaded"
        @click="appUpdateStore.restartAppAndUpdate()"
      >
        Restart & install update
      </ButtonComponent>
      <div class="progressbar" v-else-if="appUpdateStore.isUpdateInProgress">
        <div class="bar" :style="{width: `${appUpdateStore.updateProgress * 100}%`}"/>
      </div>
      <ButtonComponent
        :iconNameType="IconNameType.ArrowDownToLine"
        :style="StyleVariantType.ClearFilled"
        :color="ColorVariantType.Neutral"
        v-else-if="appUpdateStore.isUpdateAvailable"
        @click="appUpdateStore.downloadAppUpdate()"
      >
        Download
      </ButtonComponent>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '../../../styles/variables' as variables;

.native-app-update-bar {
  height: 32px;
  background: map-get(variables.$colors, primary);
  z-index: 9999999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
}

.title {

}


.left {
  display: flex;
  padding-left: 10px;
  gap: 5px;
}

.right {
  display: flex;
  height: 100%;
  align-items: center;
}

.icon {
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-size: 12px;
}

.progressbar {
  width: 200px;
  height: 6px;
  background: hsla(0, 0%, 0%, 0.2);
  border-radius: 10px;
  margin-right: 10px;
}

.bar {
  background: white;
  height: 100%;
  border-radius: 10px;
}
</style>
