import {createGlobalState, tryOnMounted, useBroadcastChannel, useEventListener} from '@vueuse/core'
import {computed, ref, watch} from 'vue'
import {BroadcastChannelType} from '@toolify/client/src/modules/broadcast/enum/BroadcastChannelType'

export const useBrowserTabs = createGlobalState(() => {
  const tabId = new Date().getTime()

  const activeBrowserTabIds = ref<Set<number>>(new Set<number>())
  const {
    data,
    post,
  } = useBroadcastChannel({name: BroadcastChannelType.BrowserTabs})

  watch(data, (newValue) => {
    if(newValue && typeof newValue === 'string' && newValue.startsWith('tabClosed-')) {
      const closedTabId = Number(newValue.replace('tabClosed-', ''))
      activeBrowserTabIds.value.delete(closedTabId)
    } else if(newValue) {
      activeBrowserTabIds.value.add(Number(newValue))
    }
  })

  useEventListener(window, 'beforeunload', () => {
    post(`tabClosed-${tabId}`)
  })

  tryOnMounted(() => {
    post(tabId)
    activeBrowserTabIds.value.add(tabId)
  })

  const isPrimaryTab = computed(() => {
    const sortedTabIds = [...activeBrowserTabIds.value].sort((a, b) => a - b)
    return tabId === sortedTabIds[sortedTabIds.length - 1]
  })

  return {
    activeBrowserTabIds,
    isPrimaryTab,
  }
})
