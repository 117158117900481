import {Pinia} from 'pinia'
import {RouteName} from '@toolify/client/src/setup/router/enum/RouteName'
import {useDatabaseApiStore} from '@toolify/client/src/stores/api/DatabaseApiStore/useDatabaseApiStore'
import {RouteLocationNormalized} from 'vue-router'
import {useDatabaseViewApiStore} from '@toolify/client/src/stores/api/DatabaseViewApiStore/useDatabaseViewApiStore'

export function beforeDatabasePageRouterHookFactory(pinia: Pinia) {
  return async function beforeDatabasePageRouterHook(to: RouteLocationNormalized) {
    const databaseApiStore = useDatabaseApiStore()
    const databaseViewApiStore = useDatabaseViewApiStore()
    const [
      getDatabaseResponse,
      getDatabaseViewsResponse,
    ] = await Promise.all([
      databaseApiStore.getDatabase(to.params.databaseId as string),
      databaseViewApiStore.getViews(to.params.databaseId as string),
    ])

    const database = getDatabaseResponse.database
    const databaseViews = getDatabaseViewsResponse.databaseViews
    const lastUserDatabaseViewId = window.localStorage.getItem(`current-view-db-${database.id}`)
    
    const databaseViewId = lastUserDatabaseViewId && databaseViews.some(view => view.id === lastUserDatabaseViewId)
      ? lastUserDatabaseViewId
      : databaseViews.find(view => view.id === database.defaultDatabaseViewId)?.id
        || databaseViews.find(view => view.position === 0)?.id
        || databaseViews?.[0]?.id

    return {
      name: RouteName.DatabaseView,
      params: {
        databaseViewId,
        databaseId: database.id,
        workspaceName: to.params.workspaceName,
      },
    }
  }
}
