export enum CypressUniqueNameType {
  AddPropertyButton = 'add-property-button',
  CreateWorkspaceButton = 'create-workspace-button',
  CreateWorkspaceFriendlyName = 'create-workspace-friendly-name',
  CreateWorkspaceFinalButton = 'create-workspace-final-button',
  AddResourceButton = 'add-resource-button',
  ResourceItemTitle = 'resource-item-title',
  PropertiesContainer = 'properties-container',
  PropertySearchInput = 'property-search-input',
  ChooseItemComponent = 'choose-item-component',
  ClosePropertyButtonComponent = 'close-property-button-component',
  AddPropertyTitleInput = 'add-property-title-input',
  AddPropertyButtonComponent = 'add-property-button-component',
  HeaderTable = 'header-table',
  AddItemUnderGroupButton = 'add-item-under-group-button',
  CellValue = 'cell-value',
  CellItemInput = 'cell-item-input',
  SelectItemCheckbox = 'select-item-checkbox',
  CommandPanelContainer = 'command-panel-container',
  CommandPanelContainerHeader = 'command-panel-container-header',
  CommandPanelContainerDuplicate = 'command-panel-container-duplicate',
  CommandPanelContainerEdit = 'command-panel-container-edit',
  CommandPanelContainerClose = 'command-panel-container-close',
  CommandPanelContainerDelete = 'command-panel-container-delete',
  NotificationComponent = 'notification-component',
  NotificationButton ='notification-button',
  NotificationMessage ='notification-message',
  NotificationClose='notification-close',
  NotificationDescription = 'notification-description',
  AddItemHeaderButton = 'add-item-header-button',
  SelectSpaceComponent = 'select-space-component',
  SelectSpaceHeader = 'select-space-header',
  SelectSpaceItem = 'select-space-item-container',
  AddSpaceButton = 'add-space-button',
  SpaceOptionsButton = 'space-options-button',
  ContextMenuComponent = 'context-menu-component',
  MenuPosition = 'menu-position',
  SpaceInputComponent = 'space-input-component',
  HeaderHideButton ='header-hide-button',
  ModalHeaderComponent = 'modal-header-component',
  HidePropertiesComponent = 'hide-properties-component',
  DatabasePropertySettings = 'database-property-settings',
  SettingsPropertyContainer = 'settings-property-container',
  SettingsPropertyContainerTitle = 'settings-property-container-title',
  SettingsPropertyContainerIcons = 'settings-property-container-icons',
  CopyIcon = 'copy-icon',
  TextItemValue = 'text-item-value',
  DropdownComponentContent = 'dropdown-component-content',
  MarkdownEditorWrapper = 'markdown-editor-wrapper',
  ColumnSettingsPrefix = 'column-settings-prefix',
  ColumnSettingsSuffix = 'column-settings-suffix',
  NumberItemValue = 'number-item-value',
  ToggleComponent = 'toggle-component',
  PropertyActionSettings = 'property-action-settings',
  DatabasePropertyHeaderDropdownContentComponent = 'database-property-header-dropdown-content-component',
  ClearValueComponent = 'clear-value-component',
  PeopleNick = 'people-nick',
  AddEditStatusButton = 'add-edit-status-button',
  CreateNewStatusButton = 'create-new-status-button',
  StatusItem = 'status-item',
  StatusItemDelete = 'status-item-delete',
  StatusItemEdit = 'status-item-edit',
  StatusItemInput = 'status-item-input',
  StatusDropdownOkButton = 'status-dropdown-ok-button',
  StatusItemWrapper = 'status-item-wrapper',
  StatusItemEditButton = 'status-edit-button',
  StatusItemEditIcon = 'status-edit-icon',
  CalendarPicker = 'calendar-picker',
  CalendarPickerDay = 'calendar-picker-day',
  CalendarPickerDayToday= 'calendar-picker-day-today',
  CalendarPickerAddRange = 'calendar-picker-add-range',
  LinkInputModalHref = 'link-input-modal-href',
  LinkInputModalTitle = 'link-input-modal-title',
}
